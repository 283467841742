import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Chrome vill ta över datasamlandet, Pine64 uppdaterar och VLC fyller 20 år. Vi snackar också om tangentbord, RSS, VPN, kryptering och mycket mer.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Pine64 annonserar nytt: `}<a parentName="li" {...{
          "href": "https://www.pine64.org/2021/01/15/january-update-happy-new-gear/"
        }}>{`https://www.pine64.org/2021/01/15/january-update-happy-new-gear/`}</a></li>
      <li parentName="ul">{`Chrome blir spårningsmaskin:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.wired.com/story/chrome-cookie-update-advertisers-google/"
            }}>{`https://www.wired.com/story/chrome-cookie-update-advertisers-google/`}</a></li>
          <li parentName="ul">{`The Privacy Sandbox: `}<a parentName="li" {...{
              "href": "https://www.chromium.org/Home/chromium-privacy/privacy-sandbox"
            }}>{`https://www.chromium.org/Home/chromium-privacy/privacy-sandbox`}</a></li>
        </ul></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`System76 tangentbord: `}<a parentName="li" {...{
          "href": "https://blog.system76.com/post/612874398967513088/making-a-keyboard-the-system76-approach"
        }}>{`https://blog.system76.com/post/612874398967513088/making-a-keyboard-the-system76-approach`}</a></li>
      <li parentName="ul">{`Element inte längre blockad: `}<a parentName="li" {...{
          "href": "https://twitter.com/element_hq/status/1355663753380032512"
        }}>{`https://twitter.com/element_hq/status/1355663753380032512`}</a></li>
      <li parentName="ul">{`Facebook -> RSS: `}<a parentName="li" {...{
          "href": "https://fosstodon.org/@yshalsager/105673994238650386"
        }}>{`https://fosstodon.org/@yshalsager/105673994238650386`}</a></li>
      <li parentName="ul">{`VLC 20 år: `}<a parentName="li" {...{
          "href": "https://www.videolan.org/press/videolan-20.html"
        }}>{`https://www.videolan.org/press/videolan-20.html`}</a></li>
      <li parentName="ul">{`Mullvad VPN uppgraderar: `}<a parentName="li" {...{
          "href": "https://mullvad.net/en/blog/2021/2/4/we-have-upgraded-all-our-openvpn-servers-openvpn-25/"
        }}>{`https://mullvad.net/en/blog/2021/2/4/we-have-upgraded-all-our-openvpn-servers-openvpn-25/`}</a></li>
      <li parentName="ul">{`Jeff Bezos lämnar VD-posten på Amazon: `}<a parentName="li" {...{
          "href": "https://www.aboutamazon.com/news/company-news/email-from-jeff-bezos-to-employees"
        }}>{`https://www.aboutamazon.com/news/company-news/email-from-jeff-bezos-to-employees`}</a>{` `}<a parentName="li" {...{
          "href": "https://news.ycombinator.com/item?id=26006656"
        }}>{`https://news.ycombinator.com/item?id=26006656`}</a></li>
      <li parentName="ul">{`Mozilla VPN i Tyskland och Frankrike: `}<a parentName="li" {...{
          "href": "https://www.zdnet.com/article/mozilla-expected-to-launch-its-vpn-service-in-germany-and-france-in-q1-2021/"
        }}>{`https://www.zdnet.com/article/mozilla-expected-to-launch-its-vpn-service-in-germany-and-france-in-q1-2021/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`EU vs kryptering (tack Samuel): `}<a parentName="li" {...{
          "href": "https://protonmail.com/blog/joint-statement-eu-encryption/"
        }}>{`https://protonmail.com/blog/joint-statement-eu-encryption/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`guvcview: `}<a parentName="li" {...{
          "href": "http://guvcview.sourceforge.net/"
        }}>{`http://guvcview.sourceforge.net/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex dammar av sin PinePhone`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Problem med Matrix-länken?`}</li>
      <li parentName="ul">{`Federerat ljud: FunkWhale (tack JonArvid på mastodon)`}</li>
      <li parentName="ul">{`Förfrågan på Matrix: Distansstyrning av datorer, utan att öppna portar?`}<ul parentName="li">
          <li parentName="ul">{`remotely.one`}</li>
        </ul></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      